<template>
  <div class="plc-state">
    <v-tooltip bottom>
      <span slot="activator">
        <popover v-if="statusOffline" class="infopopover" color="red" icon="error" :header="plc.name" :tooltip="toolTip">
          {{ toolTip }}
        </popover>
        <popover v-else-if="statusOnlineError" class="infopopover" color="red" icon="warning" :header="plc.name" :tooltip="toolTip">
          <plc-table-status-details :plc="plc" />
        </popover>
        <popover v-else-if="statusPartial || statusUpdating" class="infopopover" color="orange" icon="check_circle" :header="plc.name" :tooltip="toolTip">
          <plc-table-status-details :plc="plc"/>
        </popover>
        <popover v-else-if="statusOnline" class="infopopover" color="lime" icon="check_circle" :header="plc.name" :tooltip="toolTip">
          <plc-table-status-details :plc="plc"/>
        </popover>
        <popover v-else class="infopopover" color="yellow" icon="help" :header="plc.name" :tooltip="toolTip">
          {{ toolTip }}
        </popover>
      </span>
      <span>{{ toolTip }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <div slot="activator" class="plc-connection-history" @click="selected">
        <base-material-icon
          iconName="history"
        />
      </div>
      <span>{{ 'plcConnection.history' | translate }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import moment from 'moment'

import PlcTableStatusDetails from './PlcTableStatusDetails.vue'

import { PlcStatus, PlcViewModel } from '../../../../../store/modules/selectPlcUi/models'

const Resource = namespace('resource')

@Component({
  components: {
    PlcTableStatusDetails,
  },
})
export default class PlcTableStatus extends Vue {
  @Prop() public plc: PlcViewModel
  @Resource.Getter public dictionary

  @Emit('selected')
  public selected() {
    return this.plc.id
  }

  public get statusOnline() {
    return this.plc.status === PlcStatus.Online
  }

  public get statusOffline() {
    return this.plc.status === PlcStatus.Offline
  }

  public get statusOnlineError() {
    return this.plc.status === PlcStatus.ErrorOnline
  }

  public get statusPartial() {
    return this.plc.status === PlcStatus.PartiallyOnline
  }

  public get statusUpdating() {
    return this.plc.status === PlcStatus.Updating
  }

  public get toolTip() {
    switch (this.plc.status) {
      case PlcStatus.Online:
        return this.dictionary('select.plc.state.online')
      case PlcStatus.ErrorOnline:
        return  this.dictionary('select.plc.state.errorOnline')
      case PlcStatus.PartiallyOnline:
        return this.dictionary('select.plc.state.partiallyReady')
      case PlcStatus.Updating:
        return this.dictionary('select.plc.state.updating')
      case PlcStatus.Offline:
        return `${this.dictionary('select.plc.state.offline')} - ${this.dictionary('alarm.plc.offline.since')}: ${this.formatTimestamp(this.plc.lastOnline || '')}`
      default:
        return `${this.dictionary('select.plc.state.unknown')} - ${this.dictionary('alarm.plc.last.seen')}: ${this.formatTimestamp(this.plc.lastOnline || '')}`
    }
  }

  public formatTimestamp(dateTimeUtc: string) {
    return (dateTimeUtc && !dateTimeUtc.startsWith('0001')) ? moment(dateTimeUtc).local().format('D.M.Y H:mm') : 'n/a'
  }
}
</script>
<style lang="scss" scoped>
.plc-state {
  display: flex;
}
.plc-connection-history {
  margin-left: 5px;
  cursor: pointer;
}
</style>
