import { RootState } from '@/store/types'
import { PlcType } from '@ecocoach/domain-store-modules/src/common'
import { ComponentFirmwareState, ComponentFirmwareType } from '@ecocoach/domain-store-modules/src/plcOperation/models'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { groupedBy, matchSubstringCaseInsensitive } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { compare, validate } from 'compare-versions'
import { GetterTree } from 'vuex'
import { activeEccRelease, latestInTestEccRelease, toComponentFirmwareType, toPlcStatus } from './helpers'
import { PlcStatus, PlcTableFilterSettings, PlcViewModel } from './models'
import { SelectPlcUiGetter, SelectPlcUiState } from './types'

export const getters: GetterTree<SelectPlcUiState, RootState> = {
  [SelectPlcUiGetter.isInteracted] ({ interacted }): boolean {
    return interacted
  },
  [SelectPlcUiGetter.filterSettings] ({ filterSettings }): PlcTableFilterSettings {
    return filterSettings
  },  
  [SelectPlcUiGetter.plcs] (_, __, rootState): PlcViewModel[] {
    return rootState.plc.plcs.map(plc => {
      const status = toPlcStatus(plc.ecoCloudConnectorVersion ? plc.plcOperationState : undefined)

      const installedEccVersion = plc.ecoCloudConnectorVersion
      const activeEccVersion = rootState.plcOperation.componentFirmwareReleases.find(r => 
        r.state === ComponentFirmwareState.Active && 
        r.type === toComponentFirmwareType(plc.plcType))?.version

      const isOnline = (status === PlcStatus.Online || status === PlcStatus.PartiallyOnline) &&
        (plc.mainThreadState === 'RunningOnline' || plc.mainThreadState === 'Unknown' || plc.mainThreadState === 'OnlineError')
      
      const latestTestEccVersion = latestInTestEccRelease(rootState.plcOperation.componentFirmwareReleases, plc.plcType)?.version
  
      const eccUpdateAvailable = (
        installedEccVersion && activeEccVersion && 
        validate(installedEccVersion) && validate(activeEccVersion) &&
        compare(installedEccVersion, activeEccVersion, '<')) || false

      const eccTestUpdateAvailable = (
        installedEccVersion && latestTestEccVersion && 
        validate(installedEccVersion) && validate(latestTestEccVersion) &&
        !compare(installedEccVersion, latestTestEccVersion, '=')) || false

      return {
        ...plc,
        ecoCloudConnectorVersion: plc.ecoCloudConnectorVersion ? `${plc.ecoCloudConnectorVersion} ecoCloudConnector` : 'Communicator',
        status,
        lastOnline: plc.timeStamp ?? '',
        eccUpdateAvailable,
        eccUpdateEnabled: eccUpdateAvailable && isOnline,
        eccTestUpdateAvailable,
        eccTestUpdateEnabled: eccTestUpdateAvailable && isOnline,
        eccInstalled: !!installedEccVersion,
      } as PlcViewModel
    })
  },
  [SelectPlcUiGetter.projects] (_, localGetters): DropdownOption[] {
    const plcs: PlcViewModel[] = localGetters[SelectPlcUiGetter.plcs]
    return Array.from(groupedBy(plcs, plc => plc.projectId).values()).map(entry => {
      return {
        id: entry[0].projectId,
        label: entry[0].projectName,
      }
    })
  },
  [SelectPlcUiGetter.plcTypes] (): DropdownOption[] {
    return [
      { id: PlcType.ECO_BASIC_CONTROL_SMALL, label: PlcType.ECO_BASIC_CONTROL_SMALL },
      { id: PlcType.ECO_BASIC_CONTROL_MEDIUM, label: PlcType.ECO_BASIC_CONTROL_MEDIUM },
      { id: PlcType.ECO_BASIC_CONTROL_LARGE, label: PlcType.ECO_BASIC_CONTROL_LARGE },
      { id: PlcType.ECO_BASIC_CONTROL_LARGE_VIRTUAL, label: PlcType.ECO_BASIC_CONTROL_LARGE_VIRTUAL },
      { id: PlcType.ECO_BASIC_CONTROL_LIGHT, label: PlcType.ECO_BASIC_CONTROL_LIGHT },
    ]
  },
  [SelectPlcUiGetter.versions] (_, localGetters): DropdownOption[] {
    const plcs: PlcViewModel[] = localGetters[SelectPlcUiGetter.plcs]
    return Array.from(groupedBy(plcs, plc => plc.ecoCloudConnectorVersion).values()).map(entry => {
      return {
        id: entry[0].ecoCloudConnectorVersion,
        label: entry[0].ecoCloudConnectorVersion,
      }
    }).sort((a, b) => b.label.localeCompare(a.label)) // descending
  },
  [SelectPlcUiGetter.statuses] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: PlcStatus.Online, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.online') },
      { id: PlcStatus.ErrorOnline, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.errorOnline') },
      { id: PlcStatus.PartiallyOnline, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.partiallyReady') },
      { id: PlcStatus.Updating, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.updating') },
      { id: PlcStatus.Offline, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.offline') },
      { id: PlcStatus.Unknown, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.unknown') },
    ]
  },
  [SelectPlcUiGetter.alarms] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: true, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.isopen') },
      { id: false, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('alarm.isclosed') },
    ]
  },
  [SelectPlcUiGetter.filteredPlcs] ({ filterSettings }) {
    return (plcs: PlcViewModel[]): PlcViewModel[] => {
      let filteredPlcs = [...plcs]
      if (filterSettings.projectIds.length) {
        filteredPlcs = filteredPlcs.filter(item => filterSettings.projectIds.includes(item.projectId))
      }
      if (filterSettings.plcName) {
        filteredPlcs = filteredPlcs.filter(item => matchSubstringCaseInsensitive(item.name, filterSettings.plcName))
      }
      if (filterSettings.plcTypes.length) {
        filteredPlcs = filteredPlcs.filter(item => filterSettings.plcTypes.includes(item.plcType))
      }
      if (filterSettings.versions.length) {
        filteredPlcs = filteredPlcs.filter(item => filterSettings.versions.includes(item.ecoCloudConnectorVersion))
      }
      if (filterSettings.statuses.length) {
        filteredPlcs = filteredPlcs.filter(item => {
          return filterSettings.statuses.includes(item.status) ||
          (item.status === PlcStatus.Updating && filterSettings.statuses.includes(PlcStatus.PartiallyOnline))
        })
      }
      if (filterSettings.alarms.length) {
        filteredPlcs = filteredPlcs.filter(item => filterSettings.alarms.includes(!!item.openCriticalAlarmsCount))
      }
      return filteredPlcs
    }
  },
  [SelectPlcUiGetter.trumpfInverterFirmwareVersions] (_, __, rootState): DropdownOption[] {
    return rootState.plcOperation.componentFirmwareReleases
      .filter(release => release.type === ComponentFirmwareType.InverterTrumpf)
      .map(release => {
        const suffix = release.state === ComponentFirmwareState.InTest ? ` (Test)` :''
        return {
          id: release.id,
          label: `${release.version}${suffix}`,
        }
      }).sort((a, b) => b.label.localeCompare(a.label)) // descending
  },
  [SelectPlcUiGetter.numberOfMessagesInQueue] ({ numberOfMessagesInQueue }): number {
    return numberOfMessagesInQueue
  },
  [SelectPlcUiGetter.activeEccReleaseVersion] (_, __, rootState) {
    return (plcType: PlcType) => {
      return activeEccRelease(rootState.plcOperation.componentFirmwareReleases, plcType)
    }
  },
  [SelectPlcUiGetter.latestTestEccReleaseVersion] (_, __, rootState) {
    return (plcType: PlcType) => {
      return latestInTestEccRelease(rootState.plcOperation.componentFirmwareReleases, plcType)
    }
  },
  [SelectPlcUiGetter.supportData] ({ supportData }) {
    return supportData
  },
}
