<template>
  <v-layout row align-center>
    <v-flex shrink class="popUpInfo" v-if="attributes.label && attributes.tooltip">
      <description-popover
            class="popUpButton"
            :header="context.resolveStringResource(attributes.label)"
            :content="context.resolveStringResource(attributes.tooltip)"
          />
    </v-flex>
    <v-flex grow> 
      <base-text-button :enabled="!readonly" @buttonClicked="onClick">
        <div class="text">{{ buttonText }}</div>
        <confirm-dialog
          :show="confirmationPending"
          :header="buttonText"
          :text="context.resolveStringResource(attributes.confirmationText)"
          :confirm-button-text="'OK'"
          :cancel-button-text="context.resolveStringResource('common.button.cancel')"
          @confirmed="onExecute"
          @cancelled="confirmationPending = false"
        />
      </base-text-button>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import BaseTextButton from '../../base/BaseTextButton.vue'
import { ActionControlAttributes, ControlContextV2 } from './models'
import DescriptionPopover from '../../patterns/DescriptionPopover.vue'

@Component({
  components: {
    BaseTextButton, DescriptionPopover
  },
})
export default class EcoActionControl extends Vue {
  @Prop() public id: string
  @Prop() public context: ControlContextV2
  @Prop() public attributes: ActionControlAttributes
  @Prop() public readonly: boolean

  public confirmationPending = false

  public onClick() {
    if (this.attributes.confirmationText) {
      this.confirmationPending = true
    } else {
      this.onExecute()
    }
  }

  public onExecute() {
    this.context.executeCommand(this.id, {
      command: this.attributes.action.command,
      params: null,
    })
    this.confirmationPending = false
  }

  public get buttonText() {
    return this.context.resolveStringResource(this.attributes.action.text)
  }
}
</script>

<style lang="scss" scoped>
.base-text-button {
  width: 100%;
}
.v-btn {
  border-width: 1px;
  border-color: white;
  border-style: solid;
  margin: 0;
}
.text {
  width: 100%;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}
.popUpInfo{
  padding-left: 0px;
  padding-right: 2px;
}
</style>

<style lang="css">
</style>
