<template>
  <div>
    <v-tooltip bottom>
      <div slot="activator" class="plc-connection-state">
        <base-material-icon
          :iconName="icon"
          :color="color"
        />
        <div>{{ label | translate }}</div>
      </div>
      <span>{{ label | translate }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

import { PlcOperationStateHistoryItemViewModel, PlcStatus } from '../../../../../store/modules/plcConnectionHistoryUi/models'

@Component({
  components: {
  },
})
export default class PlcConnectionHistoryTableStatus extends Vue {
  @Prop() public model: PlcOperationStateHistoryItemViewModel

  @Emit('selected')
  public selected() {
    return this.model.id
  }

  public get icon() {
    switch (this.model.status) {
      case PlcStatus.OnlineError:
        return 'warning'
      case PlcStatus.Online:
      case PlcStatus.PartiallyOnline:
      case PlcStatus.Updating:
        return 'check_circle'
      case PlcStatus.Offline:
        return 'error'
      default:
        return 'help'
    }
  }

  public get color() {
    switch (this.model.status) {
      case PlcStatus.OnlineError:
        return 'red'
      case PlcStatus.Online:
        return 'lime'
      case PlcStatus.PartiallyOnline:
      case PlcStatus.Updating:
        return 'orange'
      case PlcStatus.Offline:
        return 'red'
      default:
        return 'yellow'
    }
  }

  public get label() {
    switch (this.model.status) {
      case PlcStatus.OnlineError:
        return 'select.plc.state.errorOnline'
      case PlcStatus.Online:
        return 'select.plc.state.online'
      case PlcStatus.PartiallyOnline:
        return 'select.plc.state.partiallyReady'
      case PlcStatus.Updating:
        return 'select.plc.state.updating'
      case PlcStatus.Offline:
        return 'select.plc.state.offline'
      default:
        return 'select.plc.state.unknown'
    }
  }
}
</script>
<style lang="scss" scoped>
.plc-connection-state {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>

