import { RootState } from '@/store/types'
import { ResourceGetter } from '@ecocoach/domain-store-modules/src/resource/types'
import { filterDate } from '@ecocoach/domain-store-modules/src/utils'
import { DropdownOption } from '@ecocoach/shared-components/src'
import { GetterTree } from 'vuex'
import { toViewModel } from './helpers'
import { PlcConnectionHistoryTableFilterSettings, PlcOperationStateHistoryItemViewModel, PlcStatus } from './models'
import { PlcConnectionHistoryUiGetter, PlcConnectionHistoryUiState } from './types'

export const getters: GetterTree<PlcConnectionHistoryUiState, RootState> = {
  [PlcConnectionHistoryUiGetter.isInteracted] ({ interacted }) {
    return interacted
  },
  [PlcConnectionHistoryUiGetter.selectedPlcId] ({ selectedPlcId }) {
    return selectedPlcId
  },
  [PlcConnectionHistoryUiGetter.filterSettings] ({ filterSettings }): PlcConnectionHistoryTableFilterSettings {
    return filterSettings
  },
  [PlcConnectionHistoryUiGetter.plcOperationStateHistoryItems] (_, __, rootState): PlcOperationStateHistoryItemViewModel[] {
    return rootState.plcConnection.plcOperationStateHistory.map(item => toViewModel(item))
  },
  [PlcConnectionHistoryUiGetter.filteredPlcOperationStateHistoryItems] ({ filterSettings }) {
    return (items: PlcOperationStateHistoryItemViewModel[]): PlcOperationStateHistoryItemViewModel[] => {
      let filteredItems = [...items]
      if (filterSettings.statuses.length) {
        filteredItems = filteredItems.filter(item => filterSettings.statuses.includes(item.status))
      }
      if (filterSettings.date) {
        filteredItems = filteredItems.filter(item => filterDate(item.timeStamp!, filterSettings.date))
      }
      return filteredItems
    }
  },
  [PlcConnectionHistoryUiGetter.statuses] (_, __, ___, rootGetters): DropdownOption[] {
    return [
      { id: PlcStatus.Online, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.online') },
      { id: PlcStatus.PartiallyOnline, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.partiallyReady') },
      { id: PlcStatus.Updating, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.updating') },
      { id: PlcStatus.Offline, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.offline') },
      { id: PlcStatus.Unknown, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.unknown') },
      { id: PlcStatus.OnlineError, label: rootGetters[`resource/${ResourceGetter.resolveStringResource}`]('select.plc.state.errorOnline') },
    ]
  },
}
